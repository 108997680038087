<template>
    <div class="container">
        <BaseHeader title="Relatório de tags" :navigation="navigation">
        </BaseHeader>

        <div class="container-table">
            <!-- Contador -->
            <div class="box-total" v-if="!loading">
                <div class="total-vendas info"></div>
                <div>
                    <p class="total-vendas">
                        Total de relatórios:
                        <span>{{ total }}</span>
                    </p>
                </div>
                <div></div>
            </div>

            <div v-else class="d-flex justify-content-center">
                <b-skeleton height="80px" width="1200px"></b-skeleton>
            </div>


            <div class="new-header-table">
                <b-col cols="12" md="4" class="container-pesquisa" :class="{ pd: isMobile }">
                    <div class="inputSearch">
                        <img src="@/assets/img/icons/search.svg" class="Pointer search" />
                        <input type="text" placeholder="Pesquisar relatório" @input="debounce" v-model="search"
                            class="input-busca" />
                    </div>
                </b-col>
                <div v-if="!isMobile">
                    <div v-if="!loading">
                        <BaseButton @click="$router.push({name: 'relatorio-tag'})" variant="primary">
                            Criar relatório
                        </BaseButton>
                    </div>

                    <div v-else class="d-flex justify-content-end mt-3">
                        <b-skeleton height="47px" width="187px"></b-skeleton>
                    </div>
                </div>
            </div>

            <!-- HEADER -->
            <b-row class="Table-header mb-1 mt-5" v-if="!isMobile">
                <b-col cols="2" class="d-none d-md-block" style="padding-left: 10px;">
                    Título
                </b-col>

                <b-col cols="3" class="d-none d-md-block" style="padding-left: 10px;">
                    Modo de apresentação
                </b-col>

                <b-col cols="2" class="d-none d-md-block" style="padding-left: 10px;">
                    Período
                </b-col>

                <b-col cols="3" class="d-none d-md-block" style="padding-left: 10px;">
                    Criado em
                </b-col>

                <b-col cols="2" class="d-none d-md-block" style="
                  padding-left: 10px;
                  padding-left: 10px;
                  display: flex !important;
                  justify-content: end;
                ">Ações</b-col>
            </b-row>

            <b-row class="Table-header mb-1 mt-5" v-if="isMobile">
                <b-col cols="6" style="padding-left: 10px;">
                    Título
                </b-col>

                <b-col cols="6" style="padding-left: 10px;">
                    Modo de apresentação
                </b-col>
            </b-row>

            <!-- END HEADER -->

            <!-- BODY -->
            <template v-if="!loading && managements.length > 0 && !isMobile">
                <b-row class="Table-body" v-for="item, index in managements" :key="item.id">
                    <!--título -->
                    <b-col cols="2" style="display: flex; gap: 25px; align-items: center">
                        {{ item.title }}
                    </b-col>

                    <b-col cols="3" style="padding: 10px !important">
                        <div style="margin-left: 66px;">
                            <img v-if="item.type_graph === 'pizza'" class="graph-icon"
                                src="@/assets/img/icons/chart-pie-slice.svg" alt="pie-graph" v-b-tooltip.hover
                                title="Gráfico em formato de pizza" />
                            

                            <img v-else-if="item.type_graph === 'bar'" class="graph-icon" src="@/assets/img/icons/chart-bar.svg" alt="bar-graph"
                                v-b-tooltip.hover title="Gráfico em formato de barra (vertical)" />

                            <img v-else class="graph-icon rotate" src="@/assets/img/icons/chart-bar.svg" alt="bar-graph"
                            v-b-tooltip.hover title="Gráfico em formato de barra (horizontal)" />
                        </div>
                    </b-col>

                    <b-col cols="2">
                        Últimos {{ item.period }} dias
                    </b-col>

                    <b-col cols="3">
                        {{ item.created_at | datetime }}
                    </b-col>

                    <b-col cols="2" class="d-flex dropListUser" style="
                    display: flex !important;
                    justify-content: end !important;
                  ">
                        <b-dropdown id="dropPage" right style="margin-top: 0 !important">
                            <template #button-content>
                                <img src="@/assets/img/icons/dots.svg" alt="dots" />
                            </template>
                            <b-dropdown-item @click="$router.push(`/${dynamicRoute}/estrategico/relatorio-tag/${item.id}`)">
                                Visualizar</b-dropdown-item>
                            <b-dropdown-item @click="deleteManagement(item, index)">Deletar</b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                </b-row>
            </template>

            <template v-if="!loading && managements.length > 0 && isMobile">
                <b-row class="Table-body" v-for="item, index in managements" :key="item.id">
                    <!--título -->
                    <b-col cols="6" style="display: flex; gap: 25px; align-items: center;">
                        {{ item.title }}
                    </b-col>

                    <b-col cols="6" style="padding: 10px !important">
                        <div style="margin-left: 66px;">
                            <img v-if="item.type_graph === 'pizza'" class="graph-icon"
                                src="@/assets/img/icons/chart-pie-slice.svg" alt="pie-graph" v-b-tooltip.hover
                                title="Gráfico em formato de pizza" />
                            

                            <img v-else-if="item.type_graph === 'bar'" class="graph-icon" src="@/assets/img/icons/chart-bar.svg" alt="bar-graph"
                                v-b-tooltip.hover title="Gráfico em formato de barra (vertical)" />

                            <img v-else class="graph-icon rotate" src="@/assets/img/icons/chart-bar.svg" alt="bar-graph"
                            v-b-tooltip.hover title="Gráfico em formato de barra (horizontal)" />
                        </div>
                    </b-col>
                </b-row>
            </template>

            <b-row v-if="!loading && !managements.length" class="Table-body justify-content-center">
                <p class="nao-encontrado">Nenhum relatório foi encontrado</p>
            </b-row>

            <!-- END BODY -->
            <b-row>
                <b-col>
                    <Paginate v-if="managements.length > 0 && total > managements.length" :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage" @to-page="toPage" @per-page="perPage" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
//Services
import TagService from "@/services/resources/TagService";
const serviceTag = TagService.build();
//Utils
import Vue from "vue";
import _ from "lodash";

import Paginate from "@/components/Paginate";


export default {
    name: "listamanagements",
    components: {
        Paginate,
    },
    data() {
        return {
            navigation: [
                { link: "Estratégico", to: "/dynamicRoute/estrategico" },
                { link: "Relatório de tags", to: this.$route.fullPath },
            ],
            managements: [],
            client: {
                width: 0,
            },
            total: 0,
            search: '',
            loading: true,
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
            }
        };
    },
    computed: {
        isMobile() {
            return this.client.width <= 768;
        },
        dynamicRoute() {
            return this.$route.params.dynamicRoute;
        }
    },

    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YY HH:ss");
        },
    },

    methods: {
        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchManagements(this.search, page);
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchManagements(this.search);
        },
        deleteManagement(management, index) {
            Vue.swal({
                title: "Relatório de tags",
                text: `Deseja realmente deletar o relatório selecionado?`,
                type: "danger",
                showCancelButton: true,
                confirmButtonText: "Deletar",
                cancelButtonText: "Cancelar",
                customClass: "sweet-container",
                confirmButtonClass: "button button-danger mt-3 mb-3",
                cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
                buttonsStyling: false,
                reverseButtons: true,
            }).then((result) => {
                if (!result.isConfirmed) {
                    return;
                }

                this.loading = true;

                serviceTag
                    .destroy({id: `/management/${management.id}`})
                    .then(() => {
                    this.loading = false;
                        this.$grToast.toast("Relatório deletado com sucesso", {
                            title: "relatório",
                            variant: "success",
                            autoHideDelay: 5000,
                            appendToast: true,
                        });
                        this.managements.splice(index, 1);
                        this.total = this.total -1;
                    })
                    .catch(() => {
                        this.$grToast.toast("Erro ao deletar relatório", {
                            title: "relatório",
                            variant: "danger",
                            autoHideDelay: 5000,
                            appendToast: true,
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        fetchManagements(search = '', page = 1) {
            this.loading = true;

            serviceTag
                .read({ id: `/management?page=${page}&per_page=${this.pagination.perPage}&search=${search}` })
                .then((response) => {
                    this.managements = response.data;
                    this.total = response.total;
                    this.pagination.totalPages = response.last_page;
                    this.pagination.currentPage = response.current_page;
                    this.pagination.perPage = response.per_page;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        canUseTagManagement() {
            serviceTag.read({id: `/management/access`}).then((r) => {
            }).catch(err => {
                this.$router.push("/dynamicRoute/AccessDenied");
            });
        },
        handleResize() {
            this.client.width = window.innerWidth;
        },
        debounce: _.debounce(function () {
            this.pagination.currentPage = 1;
            this.fetchManagements(this.search);
        }, 1000),
    },
    created() {
        if (this.$store.getters.recursos.nstrategic < 1) {
            this.$router.push("/dynamicRoute/AccessDenied");
        }
        this.canUseTagManagement();
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.fetchManagements();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted() {
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .row {
        flex-wrap: nowrap !important;
    }
}

.header-table {
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: flex-end;
}

.new-header-table {
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    padding-top: 15px;
}

.flex-sale {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.datas .vue-daterange-picker {
    width: 175px;
    font-size: 14px !important;
    color: #81858e !important;
}

.reset-data {
    font-size: 14px;
    color: #81858e;
    font-weight: normal;
    margin-left: 15px;
}

.input-busca {
    width: 100%;
    height: 50px !important;
    background: #ffffff;
    /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
    border-radius: 10px;
    padding: 20px 30px;
    padding-left: 45px !important;
    border: none;
    font-family: Montserrat;
    font-weight: normal;
    color: #81858e;
    font-size: 14px;
    transition: 0.5s;
}

.input-busca:hover,
.input-busca:focus {
    border-color: #ededf0 !important;
    font-weight: normal !important;
}

.search {
    position: absolute;
    left: 37px;
    top: 17px;
    width: 14.7px;
    height: 14.7px;
    z-index: 999;
}

.container-pesquisa {
    text-align: right;
    z-index: 1;
}

@media screen and (max-width: 1300px) {
    .search {
        left: 30px;
        top: 17px;
    }
}

.container-pesquisa {
    text-align: right;
    z-index: 1;
}

.btn-estatistica {
    margin-left: -30px;
}

.grid-report {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

@media screen and (max-width: 768px) {
    .Table-header {
        gap: 50px;
    }

    .Table-body {
        gap: 50px;
    }

    .header-table {
        display: block;
        padding: 0;
    }

    .flex-sale {
        display: block;
    }

    .flex-sale div {
        margin-left: 0;
    }

    .flex-acoes {
        margin: 15px 0;
    }

    .container-pesquisa {
        text-align: left;
    }

    .search {
        left: 30px;
    }

    .input-busca {
        width: 100%;
    }

    .btn-estatistica {
        margin-left: 0;
        margin-bottom: 20px;
    }

    .grid-report {
        grid-template-columns: 1fr 1fr;
    }
}

.dados-cliente div {
    margin-bottom: 3px;
}

.dados-cliente div:nth-child(2) {
    font-weight: normal;
    font-size: 13px;
}

.offer-name {
    font-weight: normal !important;
    font-size: 13px !important;
}

// tipo data
#type {
    margin-bottom: 0 !important;
    margin-left: 10px;
}

.Table-body {
    padding: 15px;
}

.Table-body::before {
    left: 0;
}

.title-report {
    color: var(--gray01);
    font-weight: bold;
    font-size: 20px;
}

.title-report-margin-top {
    margin-top: 41px;
}

.title-report-margin-bottom {
    margin-top: 41px;
}

.graph-icon {
    width: 32px;
}

.rotate {
    transform: rotate(90deg);
  }
</style>