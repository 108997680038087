<template>
  <div>
    <Lista/>
  </div>
</template>

<script>
import Lista from "@/components/Tags/Relatorio/Lista.vue";

export default {
  components: {
    Lista
  },
}
</script>

